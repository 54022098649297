<template>
  <DashboardTemplateCasual :title="$t('message.questionReport')">
    <h1>{{ $t("message.questionReport") }}</h1>
    <el-row :gutter="10" class="pd-b-3" justify align="middle">
      <el-col :lg="8" :md="12" :sm="12" :xs="24" class="pd-b-4">
        <el-select
          v-model="filter.sessionType"
          :placeholder="$t('questionReport.pleaseSelectSessionType')"
          class="w-100"
        >
          <el-option label="Period" value="Period"> </el-option>
          <el-option label="Casual" value="Casual"> </el-option>
        </el-select>
      </el-col>
      <el-col :lg="8" :md="12" :sm="12" :xs="24" class="pd-b-4">
        <el-select
          v-model="filter.test"
          @change="changeTest"
          :placeholder="$t('questionReport.pleaseSelectTest')"
          class="w-100"
        >
          <el-option
            v-for="item in tests"
            :key="item._id"
            :label="item.name"
            :value="item._id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :lg="8" :md="12" :sm="12" :xs="24" class="pd-b-4">
        <el-select
          v-model="filter.level"
          @change="fetchPattern"
          :placeholder="$t('questionReport.pleaseSelectLevel')"
          class="w-100"
        >
          <el-option
            v-for="item in totalLevel"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-table
      :data="tableData"
      border
      style="width: 100%"
      v-loading="loadingTable"
    >
      <el-table-column
        prop="id"
        :label="$t('questionReport.number')"
        header-align="center"
        align="center"
        width="80"
      >
      </el-table-column>
      <el-table-column
        prop="name"
        :label="$t('questionReport.name')"
        header-align="center"
      >
        <template slot-scope="scope">
          <div v-if="code === 'spatial-span-color-sequence'">
            {{ getNumberColorVector(scope.row.positions) }}
          </div>
          <div v-else-if="code === 'spatial-span-working-memory'">
            {{ getNumberObjectVector(scope.row.positions) }}
          </div>
          <div v-else-if="code === 'number-sequences'">
            {{ getNumberOrderVector(scope.row.positions) }}
          </div>
          <div v-else-if="code === 'pair-associates-learning'">
            {{ getNumberCorrectVector(scope.row.positions) }}
          </div>
          <div v-else-if="code === 'digit-span–forward'">
            {{ getNumberDegitVector(scope.row.positions, "forward") }}
          </div>
          <div v-else-if="code === 'digit-span–backward'">
            {{ getNumberDegitVector(scope.row.positions, "backward") }}
          </div>
          <div v-else-if="code === 'beep-code' || code === 'color-span'">
            {{ getKeyVector(scope.row.positions) }}
          </div>
          <div v-else-if="code === 'word-recognition'">
            <div>
              <h5 class="mg-y-less">{{ $t("questionReport.question") }}</h5>
              {{ getWordVector(scope.row.positions.question) }}
            </div>
            <div>
              <h5 class="mg-y-less">{{ $t("questionReport.answer") }}</h5>
              {{ getWordVector(scope.row.positions.answer) }}
            </div>
          </div>

          <div v-else>
            {{ getNumberVector(scope.row.positions) }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('questionReport.totalUse')"
        header-align="center"
        align="right"
        sortable
      >
        <template slot-scope="scope">
          {{ scope.row.totalUse }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('questionReport.totalPass')"
        prop="totalPass"
        header-align="center"
        align="right"
        sortable
      >
        <template slot-scope="scope">
          {{ scope.row.totalPass }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('questionReport.percent')"
        prop="percent"
        header-align="center"
        align="right"
        sortable
      >
        <template slot-scope="scope"> {{ scope.row.percent }} % </template>
      </el-table-column>
      <el-table-column
        :label="$t('questionReport.avg')"
        prop="avg"
        header-align="center"
        align="right"
        sortable
      >
        <template slot-scope="scope">
          {{ scope.row.avg }} {{ $t("message.unitSecond") }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('questionReport.sd')"
        prop="sd"
        header-align="center"
        align="right"
        sortable
      >
        <template slot-scope="scope">
          {{ scope.row.sd }} {{ scope.row.avg }} {{ $t("message.unitSecond") }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('questionReport.percentPass')"
        prop="percentPass"
        header-align="center"
        align="right"
        sortable
      >
        <template slot-scope="scope"> {{ scope.row.percentPass }} % </template>
      </el-table-column>
      <el-table-column
        prop="action"
        label=""
        header-align="center"
        align="center"
        width="80"
      >
        <template slot-scope="scope">
          <a href="#" @click="openExample(scope.row.positions)">
            <i class="el-icon-view"></i
          ></a>
        </template>
      </el-table-column>
    </el-table>

    <fullscreen v-model="fullscreen" :page-only="pageOnly">
      <div class="fullscreen-box">
        <div v-if="fullscreen" class="logo">
          <img :src="require(`../assets/logo/logo-white.png`)" />
        </div>

        <div v-if="fullscreen" class="status-box">
          <div class="tag">
            <div class="item">
              <font-awesome-icon icon="fa-solid fa-heart" /> {{ 3 }}
            </div>
          </div>
          <div class="percent">
            <p v-if="code === 'word-recognition'">
              {{ this.optionTypeWord === "th" ? "เลเวล" : "LEVEL" }}
              {{ filter.level }}
            </p>
            <p v-else>{{ $t("test.level") }} {{ filter.level }}</p>
          </div>
          <div class="close">
            <font-awesome-icon
              @click="fullscreen = false"
              icon="fa-solid fa-xmark"
            />
          </div>
        </div>

        <div v-if="fullscreen" class="fullscreen-wrapper">
          <div class="w-100">
            <SpatialSpan
              v-if="code === 'spatial-span'"
              :size="size"
              :options="options"
            />
            <SpatialSpanSequence
              v-else-if="code === 'spatial-span-sequence'"
              :size="size"
              :options="options"
            />
            <SpatialSpanColorSequence
              v-else-if="code === 'spatial-span-color-sequence'"
              :size="size"
              :options="options"
            />

            <SpatialSpanWorkingMemory
              v-else-if="code === 'spatial-span-working-memory'"
              :size="size"
              :options="options"
            />
            <NumberSequences
              v-else-if="code === 'number-sequences'"
              :size="size"
              :options="options"
            />
            <Pairassociateslearning
              v-else-if="code === 'pair-associates-learning'"
              :size="size"
              :options="options"
            />
            <DigitSpanForward
              v-else-if="code === 'digit-span–forward'"
              :size="size"
              :options="options"
            />
            <DigitSpanBackward
              v-else-if="code === 'digit-span–backward'"
              :size="size"
              :options="options"
            />
            <BeepCode
              v-else-if="code === 'beep-code'"
              :size="size"
              :options="options"
            />

            <WordRecognition
              v-if="code === 'word-recognition'"
              :size="size"
              :options="options"
              :optionTypeWord="optionTypeWord"
            />
            <ColorSpan
              v-if="code === 'color-span'"
              :size="size"
              :level="filter.level"
              :options="options"
              :optionTypeWord="optionTypeWord"
            />
          </div>
        </div>
      </div>

      <el-dialog
        title="แจ้งเตือน"
        :visible.sync="lockScreenDialogVisible"
        :close-on-click-modal="false"
        :show-close="false"
        width="100%"
        :fullscreen="true"
        class="lock-screen"
      >
        <span>กรุณาใช้งานในแนวตั้ง</span>
      </el-dialog>
    </fullscreen>
  </DashboardTemplateCasual>
</template>

<script>
import DashboardTemplateCasual from "@/template/DashboardTemplateCasual";
import SpatialSpan from "@/components/example/SpatialSpan";
import SpatialSpanSequence from "@/components/example/SpatialSpanSequence";
import SpatialSpanColorSequence from "@/components/example/SpatialSpanColorSequence";
import SpatialSpanWorkingMemory from "@/components/example/SpatialSpanWorkingMemory";
import NumberSequences from "@/components/example/NumberSequences";
import Pairassociateslearning from "@/components/example/Pairassociateslearning";
import DigitSpanForward from "@/components/example/DigitSpanForward";
import DigitSpanBackward from "@/components/example/DigitSpanBackward";
import BeepCode from "@/components/example/BeepCode";
import WordRecognition from "@/components/example/WordRecognition";
import ColorSpan from "@/components/example/ColorSpan";

import { HTTP } from "@/service/axios";

export default {
  components: {
    DashboardTemplateCasual,
    SpatialSpan,
    SpatialSpanSequence,
    SpatialSpanColorSequence,
    SpatialSpanWorkingMemory,
    NumberSequences,
    Pairassociateslearning,
    DigitSpanForward,
    DigitSpanBackward,
    BeepCode,
    WordRecognition,
    ColorSpan,
  },
  computed: {
    stimulsWord() {
      return this.$store.state.stimulsWord;
    },
  },
  watch: {
    "filter.sessionType"() {
      this.setKey();
    },
    "$i18n.locale"() {
      this.optionTypeWord = this.$i18n.locale;

      this.$store.commit("SET_BREADCRUMBS", [
        { path: "/", name: "home", i18n: true },
        { path: null, name: this.$t("message.questionReport") },
      ]);
    },
  },
  data() {
    return {
      filter: {
        sessionType: "Period",
        test: "",
        level: "",
      },
      tableData: [],
      loadingTable: true,
      tests: [],
      totalLevel: 0,
      size: 0,
      options: [],
      code: "",
      lockScreenDialogVisible: false,
      fullscreen: false,
      pageOnly: false,
      optionTypeWord: "",
      durationresponsesKey: "",
      totalUseKey: "",
      totalPassKey: "",
      totalLevelPassKey: "",
    };
  },
  mounted() {
    this.optionTypeWord = this.$i18n.locale;

    this.$store.commit("SET_BREADCRUMBS", [
      { path: "/", name: "home", i18n: true },
      { path: null, name: this.$t("message.questionReport") },
    ]);
    if (this.$device.ios && this.$device.ipad) {
      this.pageOnly = true;
    }
    window.addEventListener("resize", this.orientationchange);
    this.setKey();
    this.fetchTests();
  },
  destroyed() {
    this.$store.commit("SET_BREADCRUMBS", []);
  },
  methods: {
    setKey() {
      if (this.filter.sessionType === "Period") {
        this.durationresponsesKey = "durationresponsesPeriod";
        this.totalUseKey = "totalUsePeriod";
        this.totalPassKey = "totalPassPeriod";
        this.totalLevelPassKey = "totalLevelPassPeriod";
      } else {
        this.durationresponsesKey = "durationresponsesCasual";
        this.totalUseKey = "totalUseCasual";
        this.totalPassKey = "totalPassCasual";
        this.totalLevelPassKey = "totalLevelPassCasual";
      }
    },
    orientationchange() {
      if (window.innerHeight <= 500) {
        this.lockScreenDialogVisible = true;
      } else {
        this.lockScreenDialogVisible = false;
      }
    },
    changeTest() {
      this.filter.level = "";
      this.tableData = [];
      const find = this.tests.find((f) => f._id === this.filter.test);
      if (typeof find !== "undefined") {
        this.totalLevel = find.totalLevel;
        this.code = find.code;
      }
    },

    async fetchTests() {
      try {
        let res = await HTTP.get(`/tests/count/level`);

        if (res.data.success) {
          this.tests = res.data.result.rows;

          const item = this.tests[0];

          this.filter.test = item._id;
          this.filter.level = 1;

          this.totalLevel = item.totalLevel;
          this.code = item.code;
          this.fetchPattern();
        }
      } catch (e) {
        const error = e.response;
        console.error(error);
      }
    },
    async fetchPattern() {
      try {
        this.loadingTable = true;
        let res = await HTTP.get(
          `/patterns/by?testId=${this.filter.test}&level=${this.filter.level}`
        );

        if (res.data.success) {
          let data = [];
          res.data.result.options.map((item) => {
            let obj = item;

            obj["totalUse"] =
              typeof item[this.totalUseKey] !== "undefined" &&
              item[this.totalUseKey]
                ? item[this.totalUseKey]
                : 0;
            obj["totalPass"] =
              typeof item[this.totalPassKey] !== "undefined" &&
              item[this.totalPassKey]
                ? item[this.totalPassKey]
                : 0;
            obj["percent"] = this.callPercent(item);
            obj["avg"] = this.callAVG(item);
            obj["sd"] = this.callSD(item);
            obj["percentPass"] = this.callPercentPass(item);

            data.push(obj);
          });

          this.tableData = data;
          this.size = res.data.result.size;
        }
      } catch (e) {
        const error = e.response;
        console.error(error);
      } finally {
        this.loadingTable = false;
      }
    },
    getNumberVector(options) {
      let numbers = [];

      options.map((item) => {
        let sum = (Number(item.row) - 1) * this.size;
        let number = Number(item.column) + sum;
        numbers.push(number);
      });

      return numbers.toString();
    },
    getNumberColorVector(options) {
      let numbers = [];

      options.map((item) => {
        let sum = (Number(item.row) - 1) * this.size;
        let number = Number(item.column) + sum;
        let color = item.color === 1 ? "Green" : "Yellow";
        numbers.push(`${number}:${color}`);
      });

      return numbers.toString();
    },
    getNumberObjectVector(options) {
      let numbers = [];

      options.map((item) => {
        let sum = (Number(item.row) - 1) * this.size;
        let number = Number(item.column) + sum;
        let object = item.object ? "มี" : "ไม่มี";
        numbers.push(`${number}:${object}`);
      });

      return numbers.toString();
    },
    getNumberOrderVector(options) {
      let numbers = [];

      options.map((item) => {
        let sum = (Number(item.row) - 1) * this.size;
        let number = Number(item.column) + sum;

        numbers.push(`${number}:ลำดับ ${item.number}`);
      });

      return numbers.toString();
    },
    getNumberCorrectVector(options) {
      let numbers = [];

      options.map((item) => {
        let correct = item.correct ? "ถูก" : "ผิด";
        numbers.push(`${item.number}:${correct}`);
      });

      return numbers.toString();
    },
    getNumberDegitVector(options, type) {
      let numbers = "";

      if (type === "forward") {
        numbers = options.join("");
      } else {
        for (let index = options.length - 1; index >= 0; index--) {
          numbers += options[index];
        }
      }
      return numbers;
    },

    getKeyVector(options) {
      return options.toString();
    },
    getWordVector(options) {
      let words = [];

      options.map((item) => {
        let word = this.getWord(item);
        words.push(word);
      });

      return words.toString();
    },
    openExample(options) {
      this.fullscreen = true;
      this.options = options;
    },
    getWord(id) {
      const find = this.stimulsWord.find((f) => f.id == id);

      if (typeof find !== "undefined") {
        if (this.optionTypeWord === "th") {
          return find.th;
        } else {
          return find.en;
        }
      }
      return "";
    },
    callPercent(data) {
      let totalUse =
        typeof data[this.totalUseKey] !== "undefined"
          ? data[this.totalUseKey]
          : 0;
      let totalPass =
        typeof data[this.totalPassKey] !== "undefined"
          ? data[this.totalPassKey]
          : 0;

      if (totalUse === 0 && totalPass === 0) {
        return 0;
      } else {
        let percent = (totalPass / totalUse) * 100;

        return Math.floor(percent * 100) / 100;
      }
    },
    callPercentPass(data) {
      let totalUse =
        typeof data[this.totalUseKey] !== "undefined"
          ? data[this.totalUseKey]
          : 0;
      let totalLevelPass =
        typeof data[this.totalLevelPassKey] !== "undefined"
          ? data[this.totalLevelPassKey]
          : 0;

      if (totalUse === 0 && totalLevelPass === 0) {
        return 0;
      } else {
        let percent = (totalLevelPass / totalUse) * 100;
        return Math.floor(percent * 100) / 100;
      }
    },
    callAVG(data) {
      if (
        typeof data[this.durationresponsesKey] !== "undefined" &&
        typeof data[this.totalUseKey] !== "undefined" &&
        data[this.totalUseKey] > 0
      ) {
        if (data[this.totalUseKey] === 1) {
          return data[this.durationresponsesKey][0];
        } else {
          let sum = data[this.durationresponsesKey].reduce((a, b) => a + b, 0);
          let avg = sum / data[this.totalUseKey];

          return Math.floor(avg * 100) / 100;
        }
      } else {
        return 0;
      }
    },
    callSD(data) {
      if (
        typeof data[this.durationresponsesKey] !== "undefined" &&
        typeof data[this.totalUseKey] !== "undefined" &&
        data[this.totalUseKey] > 1 &&
        data[this.durationresponsesKey].length > 1
      ) {
        let avg = this.callAVG(data);
        let totalPowDifference = 0;
        data[this.durationresponsesKey].map((item) => {
          let difference = item - avg;
          let powDifference = difference * difference;
          totalPowDifference += powDifference;
        });

        let sd = totalPowDifference / (data[this.totalUseKey] - 1);
        let radicalsNthRoot = Math.pow(sd, 1 / 2);

        return Math.floor(radicalsNthRoot * 100) / 100;
      } else {
        return 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/element-variables.scss";

.fullscreen-box {
  background-image: url("../assets/image/bg-game.png");
  background-size: cover;
  background-color: $--color-blue-65;
  text-align: center;
  padding: 0 15vw;
  overflow-y: auto;
  height: 100%;
  position: relative;
  z-index: 1;

  .logo {
    padding: 20px 0;
    img {
      height: 72px;
    }
  }

  .status-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 20px;

    .tag {
      width: 200px;
      text-align: center;
      .item {
        width: 60px;

        background: #ff768d;
        border-radius: 12px;
        text-align: center;
        color: #fff;
        font-size: 18px;
        padding: 12px 5px;
      }
    }

    .percent {
      flex: 1;
      p {
        font-size: 18px;
        color: #fff;
      }
    }

    .close {
      width: 200px;
      color: #fff;
      font-size: 30px;
      text-align: right;
      svg {
        cursor: pointer;
      }
    }

    @media screen and (max-width: 1024px) {
      .tag,
      .close {
        width: 150px;
      }
    }

    @media screen and (max-width: 500px) {
      .tag {
        width: 80px;
        .item {
          width: 50px;
          font-size: 14px;
          padding: 10px 2px;
        }
      }

      .percent {
        width: 100%;
        p {
          font-size: 14px;
        }
      }

      .close {
        width: 80px;
        font-size: 18px;
        text-align: center;
      }
    }
  }
  .fullscreen-wrapper {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    .end-game {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 70vh;
      .title {
        color: #fff;
        margin: 0;
        font-size: 28px;
      }
      .text-level {
        padding: 10px 0;
        color: #fff;
        font-size: 20px;
        font-weight: 600;
      }
    }
  }

  @media screen and (max-width: 1100px) {
    .logo {
      display: none;
    }
    .status-box {
      padding-top: 20px;
    }
  }

  @media screen and (max-width: 500px) {
    padding: 0 15px;
    .logo {
      display: none;
    }
    .status-box {
      padding-top: 20px;
    }
  }
}

.pattern-block {
  .title {
    display: flex;
    justify-content: space-between;
    margin: 10px;
    span {
      font-weight: 600;
    }
    .life-points {
      i {
        margin: 0 2px;
        font-size: 12px;
      }
    }
  }
  .block {
    display: flex;
    align-items: center;
    justify-content: center;
    .item {
      background-color: green;
      width: 100px;
      height: 100px;
      margin: 10px;
    }
  }
}
</style>
