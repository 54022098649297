<template>
  <div
    class="pattern-block"
    :class="[`size-${size}`, { 'word-show': qWord || !aWord }]"
  >
    <div v-if="qWord" class="questtion-screen">
      {{ getWord(qWord) }}
    </div>

    <div v-if="aWord" class="title-box w-100">
      <p class="text-1" :class="optionTypeWord">
        {{ optionTypeWord === "th" ? "เลือกหนึ่งอย่าง" : "CHOOSE ONE" }}
      </p>
      <p class="text-2">
        {{
          optionTypeWord === "th"
            ? "คุณเคยเห็นคำนี้หรือไม่?"
            : "Have you ever seen this word?"
        }}
      </p>
    </div>

    <div v-if="aWord" class="answer-screen">
      {{ getWord(aWord) }}
    </div>

    <div v-if="aWord" class="answer-action">
      <el-button @click="nextAnswer" type="primary" round>{{
        optionTypeWord === "th" ? "ใช่" : "Yes"
      }}</el-button>

      <el-button @click="nextAnswer" type="primary" round>{{
        optionTypeWord === "th" ? "ไม่ใช่" : "No"
      }}</el-button>
    </div>

    <img
      v-if="qWord || !aWord"
      class="image-footer"
      :src="require(`../../assets/image/image-footer-2.png`)"
    />
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 0,
    },
    options: {
      type: Object,
      default: null,
    },
    optionTypeWord: {
      type: String,
      default: "",
    },
  },
  computed: {
    stimulsWord() {
      return this.$store.state.stimulsWord;
    },
  },
  data() {
    return {
      qWord: "",
      aWord: "",
      aIndex: 0,
      start: false,
    };
  },
  mounted() {
    this.startGame();
  },
  methods: {
    playQ(index, positions) {
      if (index <= positions.length - 1) {
        let item = positions[index];
        let time = item ? 400 : 2400;
        setTimeout(() => {
          this.qWord = item;
          index++;
          this.playQ(index, positions);
        }, time);
      }
    },
    startGame() {
      this.aIndex = 0;
      this.qWord = "";
      this.aWord = "";
      this.start = false;

      setTimeout(() => {
        let positions = [];
        let timout = 0;
        this.options.question.map((item, index) => {
          if (index > 0) {
            positions.push("");
            timout += 400;
          }
          positions.push(item);
          timout += 2400;
        });

        var index = 0;
        this.playQ(index, positions);

        setTimeout(() => {
          this.qWord = "";

          this.start = true;
          this.aWord = this.options.answer[0];
        }, timout);
      }, 500);
    },

    getWord(id) {
      const find = this.stimulsWord.find((f) => f.id == id);

      if (typeof find !== "undefined") {
        if (this.optionTypeWord === "th") {
          return find.th;
        } else {
          return find.en;
        }
      }
      return "";
    },
    nextAnswer() {
      this.aIndex++;
      if (this.aIndex < this.options.answer.length) {
        this.aWord = this.options.answer[this.aIndex];
      }
    },
  },
};
</script>

<style scoped lang="scss">
.pattern-block {
  background-color: #fff;
  border-radius: 32px;
  padding: 80px 40px;
  min-height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 63vh;
  margin-top: 0;

  .title-box {
    text-align: left;
    .text-1 {
      font-size: 12px;
      margin: 0;
      padding-bottom: 10px;
      &.th {
        font-size: 14px;
      }
    }
    .text-2 {
      font-size: 16px;
      font-weight: 600;
      margin: 0;
      padding-bottom: 10px;
    }
  }
  &.word-show {
    margin-bottom: 390px;
    height: 30vh;
  }

  .image-footer {
    position: absolute;
    bottom: -330px;
    width: 400px;
    z-index: 99;
  }

  @media screen and (max-width: 1600px) {
    height: 55vh;

    &.word-show {
      margin-bottom: 280px;
      height: 25vh;
    }

    .image-footer {
      bottom: -270px;
      width: 350px;
    }
  }

  @media screen and (max-width: 1280px) {
    height: 52vh;
    &.word-show {
      margin-bottom: 230px;
      height: 25vh;
    }

    .image-footer {
      bottom: -225px;
      width: 300px;
    }
  }

  @media screen and (max-width: 1199px) {
    height: 53vh;
    &.word-show {
      margin-bottom: 230px;
      height: 25vh;
    }

    .image-footer {
      bottom: -230px;
      width: 300px;
    }
  }

  @media screen and (max-width: 1100px) {
    height: 60vh;
    &.word-show {
      margin-bottom: 230px;
      height: 27vh;
    }

    .image-footer {
      bottom: -230px;
      width: 300px;
    }
  }

  @media screen and (max-width: 1024px) {
    height: 63vh;
    &.word-show {
      margin-bottom: 230px;
      height: 34vh;
    }

    .image-footer {
      bottom: -225px;
      width: 300px;
    }
  }

  @media screen and (max-width: 918px) {
    height: 72vh;
    &.word-show {
      margin-bottom: 280px;
      height: 53vh;
    }

    .image-footer {
      bottom: -245px;
      width: 300px;
    }
  }

  @media screen and (max-width: 800px) {
    height: 70vh;

    &.word-show {
      margin-bottom: 280px;
      height: 49vh;
    }

    .image-footer {
      bottom: -245px;
      width: 300px;
    }
  }

  @media screen and (max-width: 500px) {
    padding: 40px 20px;
    height: calc(100vh - 185px);

    &.word-show {
      padding: 40px 0px;
      background-color: transparent;
      justify-content: center;
      margin-bottom: 165px;
      height: calc(100vh - 340px);
    }

    .image-footer {
      bottom: -155px;
      width: 250px;
    }
  }
}
</style>