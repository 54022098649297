var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pattern-block color",class:("size-" + _vm.size)},[_c('div',{staticClass:"question-color"},[(_vm.activeColor == 1)?_c('div',{staticClass:"item color-1",class:{ active: _vm.activeColor == 1 }},[_vm._v(" Green ")]):_vm._e(),(_vm.activeColor == 2)?_c('div',{staticClass:"item color-2",class:{ active: _vm.activeColor == 2 }},[_vm._v(" Yellow ")]):_vm._e()]),_vm._l((_vm.size),function(row){return _c('div',{key:("row" + row),staticClass:"row"},_vm._l((_vm.size),function(column){return _c('div',{key:("column" + column),staticClass:"column"},[_c('div',{staticClass:"item show-color",class:[
          {
            mark: _vm.isMarked(row, column),
          },
          {
            'color-1': _vm.isColor(row, column, 1),
          },
          {
            'color-2': _vm.isColor(row, column, 2),
          } ]})])}),0)}),_c('div',{staticClass:"action-color"},[(_vm.activeColor == 1)?_c('div',{staticClass:"item"},[_vm._v("Green")]):_vm._e(),(_vm.activeColor == 2)?_c('div',{staticClass:"item"},[_vm._v("Yellow")]):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }