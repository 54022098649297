<template>
  <div class="pattern-block" :class="[`size-${size}`, { 'image-show': showQ }]">
    <div v-if="showQ" class="qScreen">
      <div v-if="qNumber" class="box">
        {{ qNumber }}
      </div>
    </div>
    <div v-else class="numeric-container">
      <div class="numeric-screen">
        <el-input readonly> </el-input>
      </div>
      <div class="numeric-keypad">
        <div
          v-for="(item, index) in numbers"
          :key="index"
          class="numeric-box"
          :class="{ hide: item === '' }"
        >
          <font-awesome-icon v-if="item === 'Reset'" icon="fa-solid fa-trash" />
          <font-awesome-icon
            v-else-if="item === 'Del'"
            icon="fa-solid fa-delete-left"
          />
          <span v-else>
            {{ item }}
          </span>
        </div>
      </div>
      <div class="text-center">
        <el-button
          @click="sendAnswer"
          type="primary"
          :loading="reload"
          class="w-100"
          round
          >{{ $t("test.btnSendAnswer") }}</el-button
        >
      </div>
    </div>
    <img
      v-if="showQ"
      class="image-footer-1"
      :src="require(`../../assets/image/image-footer-1.png`)"
    />
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 0,
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      qNumber: "",
      numbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, "Del", 0, "Reset"],
      showQ: true,
    };
  },
  mounted() {
    this.startGame();
  },
  methods: {
    playQ(index, positions) {
      if (index <= positions.length - 1) {
        let item = positions[index];
        let time = item ? 400 : 1200;
        setTimeout(() => {
          this.qNumber = item;
          index++;
          this.playQ(index, positions);
        }, time);
      }
    },
    startGame() {
      this.showQ = true;
      let positions = [];
      let timout = 0;
      this.options.map((item, index) => {
        if (index > 0) {
          positions.push("");
          timout += 400;
        }
        positions.push(item);
        timout += 1200;
      });

      var index = 0;
      this.playQ(index, positions);

      setTimeout(() => {
        this.qNumber = "";
        this.showQ = false;
      }, timout);
    },
  },
};
</script>

<style scoped lang="scss">
.pattern-block {
  background-color: #fff;
  border-radius: 32px;
  min-height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75vh;
  margin-top: 0;
  padding: 20px;

  .numeric-container {
    width: 290px;
  }
  &.image-show {
    margin-bottom: 175px;
    height: 59vh;
  }

  .image-footer-1 {
    position: absolute;
    bottom: -193px;
    right: 0;
    width: 400px;
    z-index: 99;
  }

  @media screen and (max-width: 1600px) {
    height: 69vh;

    &.image-show {
      margin-bottom: 175px;
      height: 50vh;
    }

    .image-footer-1 {
      bottom: -192px;
      width: 350px;
    }
  }

  @media screen and (max-width: 1280px) {
    height: 67vh;
    &.image-show {
      margin-bottom: 125px;
      height: 49vh;
    }

    .image-footer-1 {
      bottom: -168px;
      width: 300px;
    }
  }

  @media screen and (max-width: 1199px) {
    height: calc(68vh - 30px);

    &.image-show {
      margin-bottom: 125px;
      height: 50vh;
    }

    .image-footer-1 {
      bottom: -170px;
      width: 300px;
    }
  }

  @media screen and (max-width: 1100px) {
    height: calc(77vh - 30px);
    &.image-show {
      margin-bottom: 125px;
      height: 56vh;
    }

    .image-footer-1 {
      bottom: -164px;
      width: 300px;
    }
  }

  @media screen and (max-width: 1024px) {
    height: calc(78vh - 30px);
    &.image-show {
      margin-bottom: 125px;
      height: 60vh;
    }

    .image-footer-1 {
      bottom: -164px;
      width: 300px;
    }
  }

  @media screen and (max-width: 918px) {
    height: calc(82vh - 30px);

    &.image-show {
      margin-bottom: 125px;
      height: 74vh;
    }

    .image-footer-1 {
      bottom: -161px;
      width: 300px;
    }
  }

  @media screen and (max-width: 700px) {
    height: 81vh;

    &.image-show {
      margin-bottom: 125px;
      height: 72vh;
    }

    .image-footer-1 {
      bottom: -160px;
      width: 300px;
    }
  }

  @media screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 135px);
    padding: 10px 20px;

    &.image-show {
      margin-bottom: 66px;
      height: calc(100vh - 220px);
    }

    .image-footer-1 {
      bottom: -110px;
      width: 180px;
    }
  }
}
</style>