<template>
  <div
    class="pattern-block"
    :class="[`size-${size}`, { 'image-show': countDown }]"
  >
    <img
      v-if="countDown"
      class="image-title"
      :src="require(`../../assets/image/image-title3.svg`)"
    />

    <div v-if="countDown" class="time-box">
      <div :style="gradient" class="time">
        <span>{{ countDown }}</span>
      </div>
    </div>
    <div
      class="color-span-container"
      :class="{ 'open-question': !showMark, full: fullScreen }"
    >
      <div v-if="showMark" class="question height">
        <div class="body">
          <div
            v-for="(item, index) in questions"
            :key="index"
            class="item"
            :style="`background-color:${getColorCode(item)};`"
          ></div>
        </div>
      </div>
      <div v-else>
        <div class="question">
          <div class="body">
            <div
              v-for="(item, index) in questions"
              :key="index"
              :id="`color-${index}`"
              class="item"
            >
              <!-- <span :style="`${getTextColor(index)}`">{{ index + 1 }} </span> -->
            </div>
          </div>
        </div>
        <el-row :gutter="10" class="answer">
          <el-col :span="6"
            ><div
              class="item"
              :style="`background-color:${getColorCode('orange')};`"
            >
              <span :style="`color:#fff;`">Orange</span>
            </div></el-col
          >
          <el-col :span="6"
            ><div
              class="item"
              :style="`background-color:${getColorCode('yellow')};`"
            >
              <span :style="`color:#fff;`">Yellow</span>
            </div></el-col
          >
          <el-col :span="6"
            ><div
              class="item"
              :style="`background-color:${getColorCode('green')};`"
            >
              <span :style="`color:#fff;`">Green</span>
            </div></el-col
          >
          <el-col :span="6"
            ><div
              class="item"
              :style="`background-color:${getColorCode('skyblue')};`"
            >
              <span :style="`color:#fff;`">Blue</span>
            </div></el-col
          >

          <el-col :span="6"
            ><div
              class="item"
              :style="`background-color:${getColorCode('purple')};`"
            >
              <span :style="`color:#fff;`">Purple</span>
            </div></el-col
          >
          <el-col :span="6"
            ><div
              class="item"
              :style="`background-color:${getColorCode('red')};`"
            >
              <span :style="`color:#fff;`">Red</span>
            </div></el-col
          >
          <el-col :span="6"
            ><div
              class="item"
              :style="`background-color:${getColorCode('black')};`"
            >
              <span :style="`color:#fff;`">Black</span>
            </div></el-col
          >
          <el-col :span="6"
            ><div
              class="item"
              :style="`background-color:${getColorCode('white')};`"
            >
              <span :style="`color:#8E8E8E;`">White</span>
            </div></el-col
          >
        </el-row>

        <div class="action-answer">
          <el-button plain round>{{ $t("test.btnSendReset") }}</el-button>

          <el-button plain round>{{ $t("test.btnSendDelete") }}</el-button>

          <el-button type="primary" round>{{
            $t("test.btnSendAnswer")
          }}</el-button>
        </div>
      </div>

      <div v-if="countDown" class="start-game">
        <el-button @click="play" type="primary">{{
          $t("test.btnStartGame")
        }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import "@/service/other";

export default {
  props: {
    size: {
      type: Number,
      default: 0,
    },
    level: {
      type: Number,
      default: 0,
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    gradient() {
      return {
        background: `conic-gradient(#FF9B57 0%, #FF9B57 ${
          this.countDown * 10
        }%, #FFDAC1 ${this.countDown * 10}%, #FFDAC1 0%)`,
      };
    },
    fullScreen() {
      if (this.sizeScreen > 1600 && this.level >= 13) {
        return true;
      } else if (this.sizeScreen <= 1600 && this.level >= 9) {
        return true;
      } else if (this.sizeScreen <= 1280 && this.level >= 7) {
        return true;
      } else if (this.sizeScreen <= 1199 && this.level >= 7) {
        return true;
      } else if (this.sizeScreen <= 991 && this.level >= 4) {
        return true;
      } else if (this.sizeScreen <= 767 && this.level >= 2) {
        return true;
      } else {
        return false;
      }
    },
  },

  data() {
    return {
      showMark: true,
      countDown: null,
      myInterval: null,
      myTimeout: null,
      questions: [],

      sizeScreen: window.innerWidth,
    };
  },
  mounted() {
    this.startGame();

    window.addEventListener("resize", this.resize);
  },
  methods: {
    resize() {
      this.sizeScreen = window.innerWidth;
    },
    startGame() {
      this.questions = this.options;
      this.showMark = true;

      this.countDown = 10;
      this.myInterval = setInterval(() => {
        this.countDown--;
      }, 1000);

      this.myTimeout = setTimeout(() => {
        clearInterval(this.myInterval);
        this.countDown = null;
        this.showMark = false;
      }, 10000);
    },
    play() {
      clearInterval(this.myInterval);
      clearTimeout(this.myTimeout);
      this.countDown = null;
      this.showMark = false;
    },

    getTextColor(index) {
      if (
        this.answers[index] === "skyblue" ||
        this.answers[index] === "purple" ||
        this.answers[index] === "red" ||
        this.answers[index] === "black"
      ) {
        return "color: #fff;opacity: 1;";
      } else {
        return "color: #606266;opacity: 0.6;";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.pattern-block {
  background-color: #fff;
  border-radius: 32px;
  padding: 40px;
  min-height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 71vh;
  margin-top: 0;

  &.image-show {
    margin-top: 205px;
    height: 52vh;
    justify-content: center;
  }

  &.play-audio {
    background: transparent;
  }

  .image-title {
    position: absolute;
    top: -225px;
    width: 300px;
    z-index: -1;
  }

  .title-box {
    text-align: left;
    .text-1 {
      font-size: 16px;
      margin: 0;
      padding-bottom: 10px;
      font-weight: 600;
    }
  }

  @media screen and (max-width: 1600px) {
    height: 65vh;

    &.image-show {
      margin-top: 205px;
      height: 41vh;
    }

    .image-title {
      top: -230px;
      width: 350px;
    }
  }

  @media screen and (max-width: 1280px) {
    height: 62vh;
    &.image-show {
      margin-top: 170px;
      height: 41vh;
    }

    .image-title {
      top: -195px;
      width: 300px;
    }
  }

  @media screen and (max-width: 1199px) {
    height: 62vh;
    &.image-show {
      margin-top: 170px;
      height: 42vh;
    }

    .image-title {
      top: -195px;
      width: 300px;
    }
  }
  @media screen and (max-width: 1100px) {
    height: 71vh;
    &.image-show {
      margin-top: 169px;
      height: 47vh;
    }

    .image-title {
      top: -195px;
      width: 300px;
    }
  }

  @media screen and (max-width: 1024px) {
    height: 73vh;
    &.image-show {
      margin-top: 170px;
      height: 51vh;
    }

    .image-title {
      top: -195px;
      width: 300px;
    }
  }

  @media screen and (max-width: 918px) {
    height: 77vh;
    &.image-show {
      margin-top: 178px;
      height: 61vh;
    }

    .image-title {
      top: -195px;
      width: 300px;
    }
  }

  @media screen and (max-width: 800px) {
    height: 77vh;
    &.image-show {
      margin-top: 172px;
      height: 61vh;
    }

    .image-title {
      top: -195px;
      width: 300px;
    }
  }

  @media screen and (max-width: 500px) {
    height: calc(100vh - 155px);
    padding: 20px;
    justify-content: center;
    &.image-show {
      margin-top: 100px;
      height: calc(100vh - 250px);
    }

    .image-title {
      top: -120px;
      width: 180px;
    }
  }
}
</style>