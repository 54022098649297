<template>
  <div class="pattern-block" :class="`size-${size}`">
    <div v-if="showLock" class="lock-box">
      <div class="lock">
        <font-awesome-icon icon="fa-solid fa-lock " />
      </div>
    </div>
    <div v-for="row in size" :key="`row${row}`" class="row">
      <div v-for="column in size" :key="`column${column}`" class="column">
        <div
          class="item"
          :class="[
            {
              mark: isMarked(row, column),
            },
          ]"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 0,
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      showAnswer: null,
    };
  },
  mounted() {
    this.startGame();
  },
  methods: {
    startGame() {
      this.showAnswer = null;

      this.options.map((item, index) => {
        setTimeout(() => {
          this.showAnswer = null;
          this.showLock = true;
          setTimeout(() => {
            this.showAnswer = item;
          }, 400);
        }, 1200 * index);
      });
      setTimeout(() => {
        this.showAnswer = null;
        this.showLock = false;
        this.answers = this.options;
      }, this.options.length * 1200);
    },

    isMarked(row, column) {
      if (!this.showAnswer) {
        return false;
      }

      return this.showAnswer.row == row && this.showAnswer.column == column
        ? true
        : false;
    },
  },
};
</script>

<style scoped lang="scss">
.pattern-block {
  background-color: #fff;
  border-radius: 32px;
  height: 74vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 20px;

  @media screen and (max-width: 1600px) {
    height: 70vh;
  }

  @media screen and (max-width: 1280px) {
    height: 67vh;
  }

  @media screen and (max-width: 1199px) {
    height: 67vh;
  }

  @media screen and (max-width: 1100px) {
    height: 74vh;
  }

  @media screen and (max-width: 918px) {
    height: 81vh;
  }

  @media screen and (max-width: 500px) {
    height: calc(100vh - 150px);
  }
}
</style>