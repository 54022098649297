<template>
  <div class="pattern-block" :class="`size-${size}`">
    <div class="time-box">
      <div v-if="countDown" :style="gradient" class="time">
        <span>{{ countDown }}</span>
      </div>
    </div>

    <div v-for="row in size" :key="`row${row}`" class="row">
      <div v-for="column in size" :key="`column${column}`" class="column">
        <div
          class="item"
          :class="[
            {
              mark: showMark && isMarked(row, column),
            },
          ]"
        ></div>
      </div>
    </div>

    <div class="start-game">
      <el-button v-if="countDown" @click="play" type="primary">{{
        $t("test.btnStartGame")
      }}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 0,
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    gradient() {
      return {
        background: `conic-gradient(#FF9B57 0%, #FF9B57 ${
          this.countDown * 10
        }%, #FFDAC1 ${this.countDown * 10}%, #FFDAC1 0%)`,
      };
    },
  },
  data() {
    return {
      countDown: null,
      myInterval: null,
      myTimeout: null,
      showMark: true,
    };
  },
  mounted() {
    this.startGame();
  },
  methods: {
    startGame() {
      setTimeout(() => {
        this.countDown = 10;
        this.myInterval = setInterval(() => {
          this.countDown--;
        }, 1000);

        this.myTimeout = setTimeout(() => {
          clearInterval(this.myInterval);
          this.countDown = null;
          this.showMark = false;
        }, 10000);
      }, 1000);
    },
    play() {
      clearInterval(this.myInterval);
      clearTimeout(this.myTimeout);
      this.countDown = null;
      this.showMark = false;
    },
    isMarked(row, column) {
      let find = this.options.find((f) => f.row == row && f.column == column);

      return typeof find !== "undefined" ? true : false;
    },
  },
};
</script>

<style scoped lang="scss">
.pattern-block {
  background-color: #fff;
  border-radius: 32px;
  height: 74vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;

  @media screen and (max-width: 1600px) {
    height: 70vh;
  }

  @media screen and (max-width: 1280px) {
    height: 67vh;
  }

  @media screen and (max-width: 1199px) {
    height: 67vh;
  }

  @media screen and (max-width: 1100px) {
    height: 75vh;
  }

  @media screen and (max-width: 918px) {
    height: 81vh;
  }

  @media screen and (max-width: 500px) {
    height: calc(100vh - 150px);
  }
}
</style>