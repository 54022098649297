<template>
  <div class="pattern-block small image-show" :class="[`size-${size}`]">
    <img
      class="image-title"
      :src="require(`../../assets/image/image-title1.png`)"
    />
    <div class="row pb-1">
      <div class="column">
        <div v-if="countDown > 0" class="item hide-box">
          <div class="time-box not-padding">
            <div :style="gradient" class="time">
              <span>{{ countDown }}</span>
            </div>
          </div>
        </div>
        <div
          v-if="countDown <= 0"
          class="item"
          :class="{
            mark: !showQ,
          }"
        >
          <Stimul
            v-if="question && showQ"
            :option="getOption(question.number)"
          />
        </div>
      </div>
    </div>

    <div v-for="row in rows" :key="`row${row}`" class="row">
      <div v-for="column in size" :key="`column${column}`" class="column">
        <div
          class="item"
          :class="{
            mark: !showA,
            'visibility-hide': !getOption((row - 1) * size + column),
          }"
        >
          <Stimul v-if="showA" :option="getOption((row - 1) * size + column)" />
        </div>
      </div>
    </div>
    <div class="start-game">
      <el-button v-if="countDown" @click="play" type="primary">{{
        $t("test.btnStartGame")
      }}</el-button>
    </div>
  </div>
</template>

<script>
import Stimul from "@/components/stimul/Stimul";

export default {
  components: {
    Stimul,
  },
  props: {
    size: {
      type: Number,
      default: 0,
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    gradient() {
      return {
        background: `conic-gradient(#FF9B57 0%, #FF9B57 ${
          this.countDown * 10
        }%, #FFDAC1 ${this.countDown * 10}%, #FFDAC1 0%)`,
      };
    },
    stimuls() {
      return this.$store.state.stimuls;
    },
    question() {
      let item = this.options.find((f) => f.correct);

      if (typeof item === "undefined") {
        return null;
      }

      return item;
    },
    rows() {
      return Math.ceil(this.options.length / this.size);
    },
  },
  data() {
    return {
      showQ: false,
      showA: true,
      countDown: 10,
      myInterval: null,
      myTimeout: null,
    };
  },
  mounted() {
    this.startGame();
  },
  methods: {
    startGame() {
      this.countDown = 10;

      this.showQ = false;
      this.showA = true;

      setTimeout(() => {
        this.countDown = 9;
        this.myInterval = setInterval(() => {
          this.countDown--;
        }, 1000);

        this.myTimeout = setTimeout(() => {
          clearInterval(this.myInterval);
          this.countDown = 0;
          this.showQ = true;
          this.showA = false;
        }, 9000);
      }, 1000);
    },
    play() {
      clearInterval(this.myInterval);
      clearTimeout(this.myTimeout);
      this.countDown = 0;
      this.showQ = true;
      this.showA = false;
    },
    getOption(number) {
      let item = this.options.find((f) => f.number === number);

      if (typeof item === "undefined") {
        return null;
      }

      let find = this.stimuls.find((f) => f.id === item.stimul);

      if (typeof find === "undefined") {
        return null;
      }

      return find;
    },
  },
};
</script>

<style scoped lang="scss">
.pattern-block {
  background-color: #fff;
  border-radius: 32px;
  padding: 20px;
  min-height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 76vh;
  margin-top: 0;

  &.image-show {
    margin-top: 105px;
    height: 66vh;
  }

  .image-title {
    position: absolute;
    top: -138px;
    width: 300px;
    z-index: -1;
  }

  @media screen and (max-width: 1600px) {
    height: 70vh;

    &.image-show {
      margin-top: 76px;
      height: 62vh;
    }

    .image-title {
      top: -115px;
      width: 250px;
    }
  }

  @media screen and (max-width: 1280px) {
    height: 68vh;
    &.image-show {
      margin-top: 68px;
      height: 60vh;
    }

    .image-title {
      top: -106px;
      width: 230px;
    }
  }

  @media screen and (max-width: 1199px) {
    height: 69vh;
    &.image-show {
      margin-top: 80px;
      height: 59vh;
    }

    .image-title {
      top: -115px;
      width: 250px;
    }
  }

  @media screen and (max-width: 1100px) {
    height: 78vh;
    &.image-show {
      margin-top: 75px;
      height: 67vh;
    }

    .image-title {
      top: -115px;
      width: 250px;
    }
  }

  @media screen and (max-width: 918px) {
    height: 81vh;
    &.image-show {
      margin-top: 119px;
      height: 70vh;
    }

    .image-title {
      top: -138px;
      width: 300px;
    }
  }

  @media screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 135px);

    &.image-show {
      margin-top: 66px;
      height: calc(100vh - 200px);
    }

    .image-title {
      top: -83px;
      width: 180px;
    }
  }
}
</style>