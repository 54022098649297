<template>
  <div class="pattern-block color" :class="`size-${size}`">
    <div class="question-color">
      <div
        v-if="activeColor == 1"
        class="item color-1"
        :class="{ active: activeColor == 1 }"
      >
        Green
      </div>
      <div
        v-if="activeColor == 2"
        class="item color-2"
        :class="{ active: activeColor == 2 }"
      >
        Yellow
      </div>
    </div>
    <div v-for="row in size" :key="`row${row}`" class="row">
      <div v-for="column in size" :key="`column${column}`" class="column">
        <div
          class="item show-color"
          :class="[
            {
              mark: isMarked(row, column),
            },
            {
              'color-1': isColor(row, column, 1),
            },
            {
              'color-2': isColor(row, column, 2),
            },
          ]"
        ></div>
      </div>
    </div>
    <div class="action-color">
      <div v-if="activeColor == 1" class="item">Green</div>
      <div v-if="activeColor == 2" class="item">Yellow</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 0,
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      showAnswer: null,

      activeColor: null,
    };
  },
  mounted() {
    this.startGame();
  },
  methods: {
    startGame() {
      let colors = [1, 2];

      let randomColor = colors[Math.floor(Math.random() * colors.length)];

      this.options.map((item, index) => {
        setTimeout(() => {
          this.showAnswer = null;
          setTimeout(() => {
            this.showAnswer = item;
          }, 500);
        }, 1000 * index);
      });

      this.activeColor = null;

      setTimeout(() => {
        this.showAnswer = null;
        this.activeColor = randomColor;
      }, this.options.length * 1000);
    },

    isMarked(row, column) {
      if (!this.showAnswer) {
        return false;
      }

      return this.showAnswer.row == row && this.showAnswer.column == column
        ? true
        : false;
    },
    isColor(row, column, color) {
      let find = this.options.find(
        (f) => f.row == row && f.column == column && f.color == color
      );

      if (typeof find !== "undefined") {
        if (this.showAnswer && this.showAnswer.color === find.color) {
          return true;
        }
      }

      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.pattern-block {
  background-color: #fff;
  border-radius: 32px;
  padding: 20px;
  height: 76vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 1600px) {
    height: 70vh;
  }

  @media screen and (max-width: 1280px) {
    height: 68vh;
  }

  @media screen and (max-width: 1199px) {
    height: 67vh;
  }

  @media screen and (max-width: 1100px) {
    height: 76vh;
  }

  @media screen and (max-width: 918px) {
    height: 81vh;
  }

  @media screen and (max-width: 500px) {
    height: calc(100vh - 150px);
  }
}
</style>