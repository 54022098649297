<template>
  <div
    class="pattern-block"
    :class="[
      `size-${size}`,
      { 'image-show': showQ && !playAudio },
      { 'play-audio': playAudio },
    ]"
  >
    <img
      v-if="showQ && !playAudio"
      class="image-title"
      :src="require(`../../assets/image/image-title2.png`)"
    />

    <img
      v-if="showQ && playAudio"
      class="image-play"
      :src="require(`../../assets/image/play.png`)"
    />

    <div v-if="!playAudio" class="play-container">
      <div v-if="showQ">
        <img v-if="playAudio" :src="require(`../../assets/image/sound.gif`)" />
        <div @click="handlePlayAudio" v-else class="play">
          <img
            class="image-speak"
            :src="require(`../../assets/image/speak.png`)"
          />
          <p class="text">Press to play</p>
        </div>
      </div>
      <div v-else class="answer">
        <div class="title-box">
          <p class="text-1">Press your answer</p>
        </div>

        <div class="screen">
          <div class="background">
            <div class="box"></div>
          </div>
        </div>
        <div class="action">
          <el-button round><div class="beep type-1"></div></el-button>
          <el-button round><div class="beep type-2"></div></el-button>
        </div>
        <div class="action-answer">
          <el-button plain round>{{ $t("test.btnSendReset") }}</el-button>

          <el-button plain round>{{ $t("test.btnSendDelete") }}</el-button>

          <el-button type="primary" round>{{
            $t("test.btnSendAnswer")
          }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 0,
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      playAudio: false,
      showQ: true,
      stop: false,
    };
  },
  beforeDestroy() {
    this.stop = true;
  },
  methods: {
    async playBeep1() {
      const audio = new Audio(require(`../../assets/video/censor-beep-1.mp3`));
      await audio.play();
    },
    async playBeep2() {
      const audio = new Audio(require(`../../assets/video/censor-beep-2.mp3`));
      await audio.play();
    },
    handlePlayAudio() {
      this.playAudio = true;
      this.startGame();
    },
    playQ(index, positions) {
      if (index <= positions.length - 1) {
        if (this.stop) {
          this.endQ();
        } else {
          let item = positions[index];
          let time = index === 0 ? 500 : 1500;
          setTimeout(async () => {
            if (item === "S") {
              await this.playBeep1();
            } else {
              await this.playBeep2();
            }
            index++;
            this.playQ(index, positions);
          }, time);
        }
      } else {
        setTimeout(() => {
          this.endQ();
        }, 1500);
      }
    },
    endQ() {
      this.playAudio = false;
      this.showQ = false;
    },
    startGame() {
      var index = 0;
      this.playQ(index, this.options);
    },
  },
};
</script>

<style scoped lang="scss">
.pattern-block {
  background-color: #fff;
  border-radius: 32px;
  padding: 40px;
  min-height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 71vh;
  margin-top: 0;

  &.image-show {
    margin-top: 235px;
    height: 49vh;
  }

  &.play-audio {
    background: transparent;
  }

  .image-title {
    position: absolute;
    top: -225px;
    width: 300px;
    z-index: -1;
  }

  .title-box {
    text-align: left;
    .text-1 {
      font-size: 16px;
      margin: 0;
      padding-bottom: 10px;
      font-weight: 600;
    }
  }

  @media screen and (max-width: 1600px) {
    height: 65vh;

    &.image-show {
      margin-top: 215px;
      height: 40vh;
    }

    .image-title {
      top: -220px;
      width: 300px;
    }
  }

  @media screen and (max-width: 1280px) {
    height: 62vh;
    &.image-show {
      margin-top: 185px;
      height: 39vh;
    }

    .image-title {
      top: -190px;
      width: 250px;
    }
  }

  @media screen and (max-width: 1199px) {
    height: 62vh;
    &.image-show {
      margin-top: 185px;
      height: 39vh;
    }

    .image-title {
      top: -190px;
      width: 250px;
    }
  }

  @media screen and (max-width: 1100px) {
    height: 70vh;
    &.image-show {
      margin-top: 185px;
      height: 44vh;
    }

    .image-title {
      top: -190px;
      width: 250px;
    }
  }

  @media screen and (max-width: 918px) {
    height: 78vh;
    &.image-show {
      margin-top: 220px;
      height: 60vh;
    }

    .image-title {
      top: -215px;
      width: 300px;
    }
  }

  @media screen and (max-width: 800px) {
    height: 77vh;
    &.image-show {
      margin-top: 220px;
      height: 56vh;
    }

    .image-title {
      top: -215px;
      width: 300px;
    }
  }

  @media screen and (max-width: 500px) {
    height: calc(100vh - 155px);
    padding: 20px;

    &.image-show {
      margin-top: 100px;
      height: calc(100vh - 250px);
    }

    .image-title {
      top: -110px;
      width: 150px;
    }
  }
}
</style>